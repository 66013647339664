import React from "react";
import "./services.scss";
import CommonSection from "../../components/common-section/CommonSection";
import { services } from "../../assets/data/services";
import { Link } from "react-router-dom";
import ContactSection from "../../components/contact-section/ContactSection";
import Helmet from "../../components/helmet/Helmet";
import proMassage from "../../assets/images/pro-massage.png";

const Services = () => {
  return (
    <>
      <Helmet title={"Professional Massage Services | Relaxation & Therapy | Massage Now"} desc={"Discover a variety of massage therapies tailored to your needs. From relaxation to pain relief, our expert therapists provide customized treatments. Book now!"} canonical={"https://www.massagenow-atl.com/massage-services"}/>
      <CommonSection title={"Professional Massage Services"} />

      <div className="services">
        <div className="pro-massage-img">
          <img src={proMassage} alt="professional massage services" />
        </div>

        {services.map((item, index) => (
          <div
            id={item.id}
            className={
              index % 2 === 0 ? "service-section" : "service-section reverse"
            }
          >
            <div className="image">
              <img src={item.img} alt="service-img" />
            </div>
            <div className="content">
              <h2>
                <Link to={item?.link}>{item.title}</Link>
              </h2>
              <p className="desc">{item.desc}</p>
              <ul>
                {item.prices.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
              {item.styles ? (
                <p className="styles">Massage Styles: {item.styles}</p>
              ) : (
                ""
              )}
              <button>
                <Link
                  onclick="return gtag_report_conversion('tel:+16783880866');"
                  to="tel:+16783880866"
                >
                  Book an Appointment
                </Link>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Services;
