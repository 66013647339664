import React from "react";
import "./best-award-popup.scss";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const BestAwardPopup = ({ closePopup }) => {
  return (
    <div className={`best-award-popup`}>
      <div className="section m-section">
        <div className="right">
        <h3>Voted best of georgia 4 years in a row!</h3>
          <p className="desc">
          LIMITED TIME OFFERS – JUST MENTION THIS PROMOTION:
          <br/>
          - Couple Massage $20 Off Monday-Thursday
          <br/>
          - First Time Visitors: $20 Off one hour or longer (Every day except Saturday)
          <br/>
          Don’t forget to buy your gift certificates online today!
          </p>
          <button>
            <Link onClick={() => closePopup(false)} to={"/giftcertificates"}>
              Click Here To Purchase Now
            </Link>
          </button>
        </div>
      </div>
npm start
      <div className="icon-box" onClick={() => closePopup(false)}>
        <RxCross2 className="icon" />
      </div>
    </div>
  );
};

export default BestAwardPopup;
