import React from "react";
import "./services.scss";
import CommonSection from "../../components/common-section/CommonSection";
import { services } from "../../assets/data/services";
import { Link } from "react-router-dom";
import ContactSection from "../../components/contact-section/ContactSection";
import Helmet from "../../components/helmet/Helmet";
import proMassage from "../../assets/images/pro-massage.png";

const Kennesaw = () => {
  return (
    <>
      <Helmet title={"Professional Massage Services in Kennesaw - MassageNow"} desc={"Discover professional massage services in Kennesaw at MassageNow. Relax and unwind with our expert therapists. Book your session today!"} canonical={"https://www.massagenow-atl.com/massage-services-in-kennesaw"} />
      <CommonSection title={"Professional Massage Services in Kennesaw"} />

      <div className="services">
        <div className="pro-massage-img">
          <img src={proMassage} alt="professional massage services" />
        </div>

        <h2 className="main-heading">
          Massage Now! Voted Best of Georgia Conveniently Located in Kennesaw
        </h2>

        {services.map((item, index) => (
          <div
            className={
              index % 2 === 0 ? "service-section" : "service-section reverse"
            }
          >
            <div className="image">
              <img src={item.img} alt="service-img" />
            </div>
            <div className="content">
              <h3>{item.title}</h3>
              <p className="desc">{item.offPageDesc}</p>
              <ul>
                {item.prices.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
              {item.styles ? (
                <p className="styles">Massage Styles: {item.styles}</p>
              ) : (
                ""
              )}
              <button>
                <Link
                  onclick="return gtag_report_conversion('tel:+16783880866');"
                  to="tel:+16783880866"
                >
                  Book an Appointment
                </Link>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Kennesaw;
