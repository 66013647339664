import React from 'react'

const MapIframe = () => {
  return (
    <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.698609818003!2d-84.6417465246253!3d34.02594645983261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f53f520811c983%3A0x34c5ac7599dcaf75!2sMassage%20Now*21!5e0!3m2!1sen!2s!4v1692644903922!5m2!1sen!2s"
        width="100%"
        height="450"
        style={{ border: 0, marginBottom: "20px" }}
        loading="lazy"
      ></iframe>
  )
}

export default MapIframe
