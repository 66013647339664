import React from "react";
import "./legal.scss";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";

const Legal = () => {
  return (
    <>
    <Helmet title={"Legal"} canonical={"https://www.massagenow-atl.com/legal"} />
      <CommonSection title={"Legal"} />
      <div className="legal">
        <h1>Massage Now Terms & Conditions</h1>
        <div>
          <h5>Informed Consent – Assumed Risk and Body Massage</h5>
          <p>
            I desire to voluntarily participate in massage therapy. I understand
            that massage therapy offered by Massage Now! (“Massage Now”) is for
            the sole purpose of stress reduction, relief from muscular tension
            or for increasingcirculation. I understand that, before beginning
            any massage therapy, I need to be evaluated by my primary care
            physician(s) and any specialist physicians(s) that I may rely upon
            for my medical care. I further understand thatI have a duty to
            inform Massage Now personnel of any recommendations and/or
            limitations from my physician(s) and/or specialist(s) prior to
            participating in massage therapy. I hereby represent and agree that
            I do not haveany past/current medical conditions that may be
            affected or made worse by massage. I understand and agree that I
            shall not seek or take any medical advice from the Massage
            Therapists at Massage Now. I understand that Massage Now is not a
            doctor’s office or physical therapist office. I understand and
            acknowledge that the massage therapists and staff are not medically
            trained and do not and cannot diagnose any illness, disease, injury
            or anyother physical/mental disorder. As such, the massage therapist
            does not prescribe medical treatment or pharmaceuticals, nor do they
            perform spinal manipulations. Massage therapy is not a substitute
            for medical examinations
          </p>
          <p>
            or diagnosis and may result in some physical discomfort, stiffness
            or pain. By executing this form I hereby assume, without limitation,
            all risk of injury, damages, costs, fees, claims that may result
            from my participation in themassage therapy. I agree that I shall
            immediately notify Massage Now personnel of any unusual symptoms or
            abnormal, pain or unusual discomfort.
          </p>
        </div>

        <div>
          <h5>Informed Consent - Wavier and Release of Liability</h5>
          <p>
            I hereby agree and understand that I must advise the Massage Now
            massage therapists of any existing physical conditions prior to
            participating in each massage therapy session and I will keep the
            therapist updated as any healthissues or conditions may change. I
            understand that the physical and emotional responses to any
            treatment vary on an individual basis and that specific results are
            not guaranteed and may result in some physical discomfort,stiffness
            or pain. Therefore, in consideration for any treatment's received, I
            agree to hold harmless, indemnify, defend and release from any
            liability all employees at Massage Now, its affiliates, any related
            entities, employees,contractors, heirs, assigns, agents and
            personnel for any conditions or results, known or unknown that may
            arise as a consequence, and or all damages, injuries, physical
            discomfort, stiffness, pain, cost, fees, claims, of anymassage that
            I received including but not limited to any accidental touching or
            sexual misconduct described herein.
          </p>
        </div>

        <div>
          <h5>
            Company Policy – Customer Notice of Prohibiting Sexual Misconduct
          </h5>
          <p>
            I understand that Massage Now does not tolerate any sexual
            misconduct by its employees, contractors, agents or personnel or its
            patrons. Sexual misconduct is strictly prohibited in our workplace.
            Illicit or sexually suggestiveremarks are strictly prohibited.
            Employees, contractors, agents or personnel, are not allowed to
            display, initiate or participate in any type of sexual misconduct
            with other staff members or customers. I understand that duringany
            body massage I am to wear underwear or towel to prevent any
            accidental touching. If there is any accidental touching I
            understand and acknowledge that it is my responsibility to notify
            the massage therapist immediately.If this occurs again after I have
            notified my therapist, I understand that I must stop the massage and
            notify management immediately. I also understand that I must make
            the notification to management prior to payment of mybill or leave
            the premises.
          </p>
        </div>

        <div>
          <h5>Notice – Medical Conditions</h5>
          <p>
            If you notice any of the following conditions, please see your
            doctor for their approval prior to obtaining a massage:
            Tendon/muscle ruptures, edema, neuromuscular diseases, arthritis,
            lupus, blood clots, vascular dysfunction,bursitis, burns, artificial
            blood vessels, pregnant or a high-risk pregnancy, skin infection,
            skin lesions/open wounds/sores, any infection disease, herpes,
            rashes, cancers, irregular feelings, lightheaded, trouble breathing
            or heartattacks. If you have been diagnosed or experiencing any of
            the above, please contact your doctor about your conditions. I will
            also notify Massage Now about any special conditions I may have so
            that the therapist can avoidpoints. For example, I may be pregnant
            or I may be in the process of trying to get pregnant so that the
            therapist can avoid that may induce labor/miscarriage.
          </p>
        </div>

        <div>
          <h5>Notice – Personal Belongings</h5>
          <p>
            I agree that I will be solely responsible for all of my personal
            belongings including but not limited to Jewelry, cell phones or any
            electronics that I brought or leave at the premises. I fully release
            and indemnify Massage Nowand its affiliates as they are not liable
            for any of my personal belongings.
          </p>
        </div>

        <div>
          <h5>Governing Law, Jurisdiction, Venue</h5>
          <p>
            This Form shall be interpreted, administered and enforced in
            accordance with the laws of the State of Georgia (exclusive of its
            conflict of laws rules). Any claim or cause of action of any kind
            arising out of or connected withthis Form shall be adjudicated
            solely and exclusively in either the U.S. District Court for the
            Atlanta District of Georgia or the State Court of Cobb County,
            Georgia. Each Party consents to the personal jurisdiction of
            suchcourts for this purpose and waives any objection to the personal
            jurisdiction or venue thereof, including forum non-convenience.
            Notwithstanding the foregoing, a judgment obtained in the State of
            Georgia may be enforced inany other state, district, or territory.
          </p>
        </div>

        <div>
          <h5>Entire Agreement</h5>
          <p>
            This Form contains the entire and only agreement between the Parties
            with respect to the subject matter hereof. Any representations,
            promises, or conditions in connection therewith not incorporated
            hereinshall not be binding on either Party. This Form supersedes all
            prior understandings, representations, negotiations, promises and
            agreements relative to the subject matter hereof. No modification,
            ratification, rescission, renewal,abandonment or waiver of this
            Form, or any of its provisions, or any notice of termination hereof
            given by the Parties shall be binding unless made in writing and
            signed by the Parties or an officer of the Parties.
          </p>
        </div>

        <div>
          <h5>Severability</h5>
          <p>
            If any provision of this Form is held invalid, void or unenforceable
            under any applicable statute or rule of law, it shall to that extent
            be deemed omitted, and the balance of this Form shall be enforceable
            inaccordance with its terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default Legal;
