import React from "react";
import "./massage-insights.scss";
import arrow from "../../assets/images/arrow.svg";
import { Link } from "react-router-dom";
import { blogs } from "../../assets/data/blogs";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";
import ach4 from "../../assets/images/ach4-updated.jpg"
// import ach4 from "../../assets/images/ach4.jpg"
import leaf from "../../assets/images/leaf.svg"

const MassageInsights = () => {
  return (
    <>
      <Helmet title={"Massage insights"} canonical={"https://www.massagenow-atl.com/blog"} />
      <CommonSection title={"Massage Insights"} />

      <div className="massage-insights">

        <div className="section m-section">
          <img src={leaf} alt="leaf-icon" />
          <div className="left">
            <img src={ach4} alt="best-in-georgia-award" />
          </div>
          <div className="right">
            <h3>Best of Georgia 2024, 2023, 2022, 2021</h3>
            <p className="desc">
            Massage Now Voted Best of Georgia 2024. Four consecutive years in a row!
            </p>
            <button>
              <Link target="_blank" to={'https://fox59.com/business/press-releases/ein-presswire/673818173/massage-now-kennesaw-voted-best-of-georgia-2023-for-the-third-consecutive-year/'}>click here to learn more</Link>
            </button>
          </div>
        </div>

        <h3>latest blogs</h3>

        <div className="all-blogs">
          {blogs.map((item, index) => (
            <div className="blog-card">
              <img src={item.thumbnail} alt="thumbnail" />
              <p className="date">
                Date . <span>{item.postDate}</span>
              </p>
              <p className="title">{item.title}</p>
              <p className="short-desc">{item.shortDesc}</p>
              <Link to={item.id}>
                <img src={arrow} alt="arrow-icon" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MassageInsights;
