import React, { useEffect, useRef, useState } from "react";
import "./header.scss";
import logo from "../../assets/images/logo.png";
import { menuLinks } from "../../assets/data/menuLinks";
import { Link, NavLink, useLocation } from "react-router-dom";
import bars from "../../assets/images/bars.svg";
import cut from "../../assets/images/cut.svg";
import { MdArrowDropDown } from "react-icons/md";

const Header = () => {
  const [dropdown, setDropdown] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
    setToggle(false);
    setDropdown(false);
  }, [location.pathname]);

  const dropdownLinks = [
    {
      title: "Body Massage",
      path: "/massage-services/body-massage",
    },
    {
      title: "Couple's Massage",
      path: "/massage-services/couples-massage",
    },
    {
      title: "Sports Massage",
      path: "/massage-services/sports-massage",
    },
    {
      title: "Massage Combos",
      path: "/massage-services/massage-combos",
    },
    {
      title: "4 Hands Massage",
      path: "/massage-services/four-hands-massage",
    },
    {
      title: "Chair Massage",
      path: "/massage-services/chair-massage",
    },
    {
      title: "Lymphatic Drainage Massage",
      path: "/massage-services/lymphatic-drainage-massage",
    },
  ];

  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div ref={menuRef} className="header">
      <div className="main">
        <div className="links1">
          <ul className="links1-ul">
            {menuLinks.map(
              (item, index) =>
                index < 4 && (
                  <li key={index}>
                    {item.name === "Massage Services" ? (
                      <NavLink
                        className="adjust"
                        onMouseEnter={() => {
                          setDropdown(true);
                        }}
                        to={item.path}
                      >
                        {item.name}
                      </NavLink>
                    ) : (
                      <NavLink to={item.path}>{item.name}</NavLink>
                    )}

                    {item.name === "Massage Services" ? (
                      <MdArrowDropDown
                        className="drop-icon"
                        onMouseEnter={() => {
                          setDropdown(true);
                        }}
                        onMouseLeave={() => {
                          setDropdown(false);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </li>
                )
            )}
          </ul>

          {dropdown ? (
            <div
              className="submenu"
              onMouseEnter={() => setDropdown(true)}
              onMouseLeave={() => setDropdown(false)}
            >
              <div className="head">
                <h4>Massage Services</h4>
                <p>
                Voted Georgia's Best Massage for four consecutive years (2021–2024). Over 700 reviews on Google with an average 4.9 rating
                </p>
              </div>

              <hr />

              <ul className="submenu-links">
                {dropdownLinks.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.path}>{item.title}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <div className="links2">
          <ul className="links2-ul">
            {menuLinks.map(
              (item, index) =>
                index >= 4 && (
                  <li key={index}>
                    <NavLink to={item.path}>{item.name}</NavLink>
                  </li>
                )
            )}
          </ul>
        </div>

        <div className="nav-icons">
          {open === false ? (
            <img src={bars} alt="bars" onClick={() => setOpen(true)} />
          ) : (
            <img src={cut} alt="cut" onClick={() => setOpen(false)} />
          )}
        </div>

        <ul className={open ? "mobile-links show" : "mobile-links"}>
          {menuLinks.map((item, index) => (
            <li key={index}>
              {item.name === "Massage Services" ? (
                <ul className={`mobile-submenu ${dropdown && "show-submenu"}`}>
                  <li>
                    <NavLink to={item.path}>{item.name}</NavLink>
                  </li>

                  {dropdown &&
                    dropdownLinks.map((item, index) => (
                      <li key={index}>
                        <NavLink to={item.path}>{item.title}</NavLink>
                      </li>
                    ))}
                </ul>
              ) : (
                <NavLink to={item.path}>{item.name}</NavLink>
              )}

              {item.name === "Massage Services" ? (
                <MdArrowDropDown
                  className="drop-icon"
                  onClick={() => {
                    setDropdown(!dropdown);
                  }}
                  styles={{
                    width: "250px",
                    height: "auto",
                  }}
                />
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
