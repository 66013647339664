import React from 'react'
import "./common-section.scss"

const CommonSection = ({title}) => {
  return (
    <div className="common-section">
        <h1>{title}</h1>
    </div>
  )
}

export default CommonSection