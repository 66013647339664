import tMale from "../../assets/images/t-male.png";
// import tFemale from "../../assets/images/t-female.png";

export const testimonials = [
  {
    review:
      "I’m a cross country truck driver returns home every 10 days or so. Each time back home, I have to come here for a true professional deep tissue massage to refresh my exhausted body. The reason I chose Massage Now is simple - they are the best! No matter the therapist’s techniques, the cleanliness of the facility, or the friendliness of the staff members, they all ranked #1 on my book. I highly recommend Massage Now!",
    name: "Gary Crossing",
    img: tMale,
  },
  {
    review:
      "I love this place!! This establishment, hands down has been the best massage parlor I have ever been to. My husband has come with me both times for a couples massage and he has thoroughly enjoyed it as well. The staff made it so easy to schedule and then made you feel such at ease at the appointment. We will definitely be back in the future. Thank you again for the wonderful experience! ",
    name: "Sarah Emory",
    img: tMale,
  },
  {
    review:
      "I got my first massage here recently and I will continue to keep coming back. I got a gift card here from one of my good friends & I’d already seen all the great reviews about this place. I got an hour of the full body massage and let me tell you..I was BLOWN away from the start of the front desk lady & my masseuse. Staff is very energetic & nice. The place looks brand new & clean. Will definitely be a returning customer after my experience.",
    name: "kyla bowen",
    img: tMale,
  },
  {
    review:
      "I first visited this location about a year ago and was able to get an appointment for a 90 minute massage as the last appointment of the day. The massage had me so relaxed that I had problems driving home that evening. I have been more recently and brought my wife this time and experienced an equally relaxing massage. The worse part of this experience is having to leave at the end. My therapists have used the right amount of pressure to work out any knots but not so hard that I am sore the next day (like some other places). Looking to book my next appointment in a few days. Massage Now is phenomenal...keep up the excellent work. See you soon!!!",
    name: "Keenan Hitchens",
    img: tMale,
  },
  {
    review:
      "很好! Came in for a 60 min. body massage and I’m leaving refreshed as ever! The prices are affordable (they even have a cash deal), the staff were kind and knowledgeable, and the massage was excellent. My masseuse, Eva (I believe is her name), was quite skilled and courteous. I will definitely be going back after a long gym/sport session!",
    name: "Brandon Yang",
    img: tMale,
  },
  {
    review:
      "Best massage EVER! My masseuse was Andy. He is amazing. He delivered the best deep tissue massage I’ve had in years. I’ll definitely be returning quarterly.The customer service is great and the location is clean & inviting. The receptionist was so nice and kind. Loved her!",
    name: "Domoni Jordan",
    img: tMale,
  },
];
