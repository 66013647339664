import React from "react";
import "./gift-certificates.scss";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";
import GiftCertificateIframe from "./GiftCertificateIframe";

const GiftCertificates = () => {
  return (
    <>
      <Helmet
        title={"Gift Massage Certificates To Your Loved Ones"}
        desc={
          "Share the joy with your loved ones by gifting customizable gift certificate for custom massages. Buy a gift certificate now!"
        }
        canonical={"https://www.massagenow-atl.com/giftcertificates"}
      />

      <CommonSection
        title={"E-Gift Certificates For Massage Now! In Kennesaw"}
      />
      <div className="gift-certificates">
        <h3 className="heading">PURCHASE E-GIFT CERTIFICATES</h3>
        <p className="desc">
          Unlock smiles, share the joy: Give the perfect gift with our
          customizable gift certificates!
        </p>

        <GiftCertificateIframe />
      </div>
    </>
  );
};

export default GiftCertificates;
