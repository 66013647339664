export const menuLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Massage Services",
    path: "/massage-services",
  },
  {
    name: "Gift Certificates",
    path: "/giftcertificates",
  },
  {
    name: "Testimonials",
    path: "/what-people-are-saying",
  },
  {
    name: "Special Offers",
    path: "/special-offers",
  },
  {
    name: "Massage Insights",
    path: "/blog",
  },
  {
    name: "Employment",
    path: "/employment",
  },
  {
    name: "Legal",
    path: "/legal",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
];
