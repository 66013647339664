import React from "react";
import Helmet from "../../../components/helmet/Helmet";
import { services } from "../../../assets/data/services";
import { useState } from "react";
import CommonSection from "../../../components/common-section/CommonSection";
import { Link } from "react-router-dom";
import proMassage from "../../../assets/images/pro-massage.png";

const CouplesMassage = () => {
  const [pageData, setPageData] = useState(
    services.find((item) => item.id === "couples-massage")
  );

  return (
    <>
      <Helmet
        title={"Couples Massage Kennesaw | Relaxing & Rejuvenating"}
        desc={
          "We offer the best couples massage service in Kennesaw, Georgia. Our couples massage offers intimate experience for two in a tranquil setting. Call us now"
        }
        canonical={
          "https://www.massagenow-atl.com/massage-services/couples-massage"
        }
      />

      <CommonSection title={"Relaxing Couples Massage in Georgia"} />

      <div className="services">
        <div className="pro-massage-img">
          <img src={proMassage} alt="professional massage services" />
        </div>

        <div id={pageData.id} className="service-section">
          <div className="image">
            <img
              src={pageData.img}
              alt="service-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </div>
          <div className="content">
            <h2>{pageData.title}</h2>
            <p className="desc">{pageData.desc}</p>
            <ul>
              {pageData.prices.map((item, index) => (
                <li>{item}</li>
              ))}
            </ul>
            {pageData.styles ? (
              <p className="styles">Massage Styles: {pageData.styles}</p>
            ) : (
              ""
            )}
            <button>
              <Link
                onclick="return gtag_report_conversion('tel:+16783880866');"
                to="tel:+16783880866"
              >
                Book an Appointment
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouplesMassage;
