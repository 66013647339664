import React from "react";

const Helmet = ({ title, desc, canonical }) => {
  React.useEffect(() => {
    document.title = title;

    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.content = desc;
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = desc;
      document.head.appendChild(meta);
    }

    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute("href", canonical);
    } else {
      const link = document.createElement("link");
      link.rel = "canonical";
      link.setAttribute("href", canonical);
      document.head.appendChild(link);
    }

    return () => {
      document.title = "Voted Best Massage in Georgia | Massage Therapists";
      if (metaDesc) {
        metaDesc.content = "Rated best of Georgia 2021 and 2022!!! Massage Now offers the best massage spa service in Kennesaw. We are just located at 10 mins drive from Marrietta";
      }
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.removeAttribute("href");
      }
    };
  }, [title, desc, canonical]);

  return <></>;
};

export default Helmet;
