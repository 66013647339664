import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { hydrateRoot, createRoot  } from 'react-dom/client';


if (!window.location.host.startsWith("www")) {
  window.location =
    window.location.protocol +
    "//" +
    "www." +
    window.location.host +
    window.location.pathname;
}

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// )

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  // const root = createRoot(rootElement);
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
