import React from 'react'

const GiftCertificateIframe = () => {
  return (
    <iframe
        width="100%"
        title="gift_card"
        height="900"
        loading="lazy"
        id="loopz-card-purchase"
        src="https://my.loopz.io/gift-card/05aaae3d-a980-4b74-a9ef-164df066112e?locale=en-US"
        style={{
          borderWidth: 0,
          minWidth: "300px",
          margin: "0 0 0px 0",
          padding: "50px 0",
        }}
      ></iframe>
  )
}

export default GiftCertificateIframe
