import React from "react";
import "./services.scss";
import CommonSection from "../../components/common-section/CommonSection";
import { services } from "../../assets/data/services";
import { Link } from "react-router-dom";
import ContactSection from "../../components/contact-section/ContactSection";
import Helmet from "../../components/helmet/Helmet";
import proMassage from "../../assets/images/pro-massage.png";

const Acworth = () => {
  return (
    <>
      <Helmet title={"Premier Massage Services in Acworth - MassageNow"} desc={"Experience top-tier massage services in Acworth at MassageNow. Relaxation awaits with our skilled therapists. Schedule your appointment now!"} canonical={"https://www.massagenow-atl.com/massage-services-in-acworth"} />
      <CommonSection title={"Premier Massage Services in Acworth"} />

      <div className="services">
        <div className="pro-massage-img">
          <img src={proMassage} alt="professional massage services" />
        </div>

        {/*  */}

        <h2 className="main-heading">
          Massage Now – Voted Best of Georgia Short Distance from Acworth
        </h2>
        <p className="up-desc1">
          Are you looking for the best massage spa in Acworth, Georgia? Massage
          Now! The top rated massage therapy in Georgia is just 10 minutes drive
          from Acworth. We are located in Kennesaw and provide a range of
          massage services with a professional, experienced, and trained team.
          <span>
            Voted best massage in Georgia for 2021 and 2022, we offer the best
            and most affordable massage experience.
          </span>
        </p>
        <p className="up-desc2">
          Massages offered at Massage Now
          <span>
            At Massage Now, we provide various massage services aimed at helping
            you unwind, alleviate stress, and indulge in a serene experience.
            Our team consists of extensively trained and skilled massage
            therapists who are experienced in a variety of massage techniques.
            These techniques include:
          </span>
        </p>

        {/*  */}

        {services.map((item, index) => (
          <div
            className={
              index % 2 === 0 ? "service-section" : "service-section reverse"
            }
          >
            <div className="image">
              <img src={item.img} alt="service-img" />
            </div>
            <div className="content">
              <h3>{item.title}</h3>
              <p className="desc">{item.offPageDesc}</p>
              <ul>
                {item.prices.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
              {item.styles ? (
                <p className="styles">Massage Styles: {item.styles}</p>
              ) : (
                ""
              )}
              <button>
                <Link
                  onclick="return gtag_report_conversion('tel:+16783880866');"
                  to="tel:+16783880866"
                >
                  Book an Appointment
                </Link>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Acworth;
