import React from "react";
import "./hero.scss";
import hero from "../../assets/images/hero.webp";
import ach1 from "../../assets/images/ach1.jpg";        
import ach2 from "../../assets/images/ach2-updated.jpg";
// import ach2 from "../../assets/images/ach2.jpg";
import ach3 from "../../assets/images/ach3.jpg";
import ach4 from "../../assets/images/ach4-updated.jpg";
// import ach4 from "../../assets/images/ach4.jpg";
import { Link } from "react-router-dom";
import leaf from "../../assets/images/leaf.svg";
import line from "../../assets/images/line.svg";   
import hang from "../../assets/images/hang.svg";

const Hero = () => {
  return (
    <div className="hero">
      <div className="image">
        <img src={hero} alt="being massaged" />
      </div>

      <div className="content">
        <h1 className="label">RATED BEST MASSAGE SPA IN KENNESAW, GEORGIA</h1>
        <h2>
          PUT YOUR BODY IN <span>GOOD HANDS</span>
        </h2>
        <p className="desc">
          Visit us today and experience the ultimate in relaxation. You deserve
          to be pampered and our massage business is the perfect place to do
          just that.
        </p>
        <button>
          <Link
            onclick="return gtag_report_conversion('tel:+16783880866');"
            to="tel:+16783880866"
          >
            Book An Appointment
          </Link>
        </button>

        <div className="achievements">
          <Link>
            <img src={ach1} alt="certificate-one" />
          </Link>
          <Link>
            <img src={ach2} alt="certificate-two" />
          </Link>
          <Link>
            <img src={ach3} alt="certificate-three" />
          </Link>
          <Link
            to="https://gbj.com/kennesaw/beauty-spa/massage-now"
            target="_blank"
          >
            <img src={ach4} alt="certificate-four" />
          </Link>
        </div>
      </div>

      <img src={leaf} alt="leaf-left" />
      <img src={leaf} alt="leaf-right" />
      <img src={line} alt="line" />
      <img src={hang} alt="hang-icon" />

      {/* <div className="down"></div> */}
    </div>
  );
};

export default Hero;
