import React from "react";
import "./services.scss";
import CommonSection from "../../components/common-section/CommonSection";
import { services } from "../../assets/data/services";
import { Link } from "react-router-dom";
import ContactSection from "../../components/contact-section/ContactSection";
import Helmet from "../../components/helmet/Helmet";
import proMassage from "../../assets/images/pro-massage.png";

const Marietta = () => {
  return (
    <>
      <Helmet title={"Expert Massage Services in Marietta - MassageNow"} desc={"Enjoy serene massage services in Marietta at MassageNow. Our experienced therapists provide the perfect escape. Book your massage today!"} canonical={"https://www.massagenow-atl.com/massage-services-in-marietta"}/>
      <CommonSection title={"Expert Massage Services in Marietta"} />

      <div className="services">
        <div className="pro-massage-img">
          <img src={proMassage} alt="professional massage services" />
        </div>

        {/*  */}

        <h2 className="main-heading">
          Massage Now – Voted Best of Georgia Short Distance from Marietta
        </h2>
        <p className="up-desc1">
          If you are located in Marietta, and looking for the best spa
          experience, then you are the right place. Massage Now located in
          Kennesaw, GA is just 10 minutes drive from Marietta. Rated as having
          the best massage therapists in in Georgia for 2021 and 2022, we offer
          the best and most affordable massage experience.
        </p>
        <p className="up-desc2">
          Massages offered at Massage Now
          <span>
            At Massage Now, we offer range of massage services that can help you
            relax, reduce stress and gives your body a soothing experience. Our
            highly trained and experienced massage therapists specialize in the
            following massage techniques:
          </span>
        </p>

        {/*  */}

        {services.map((item, index) => (
          <div
            className={
              index % 2 === 0 ? "service-section" : "service-section reverse"
            }
          >
            <div className="image">
              <img src={item.img} alt="service-img" />
            </div>
            <div className="content">
              <h3>{item.title}</h3>
              <p className="desc">{item.offPageDesc}</p>
              <ul>
                {item.prices.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
              {item.styles ? (
                <p className="styles">Massage Styles: {item.styles}</p>
              ) : (
                ""
              )}
              <button>
                <Link
                  onclick="return gtag_report_conversion('tel:+16783880866');"
                  to="tel:+16783880866"
                >
                  Book an Appointment
                </Link>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Marietta;
