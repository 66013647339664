import React from "react";
import "./employment.scss";
import ContactSection from "../../components/contact-section/ContactSection";
import CommonSection from "../../components/common-section/CommonSection";
import employmentSideimg from "../../assets/images/employment-sideimg.webp";
import leaf from "../../assets/images/leaf.svg";
import Helmet from "../../components/helmet/Helmet";

const Employment = () => {
  return (
    <>
      <Helmet title={"Employment"} canonical={"https://www.massagenow-atl.com/employment"} />
      <CommonSection title={"Employment"} />
      <div className="employment">
        {/* // SECTION-1 */}
        <div className="section e-section">
          <img src={leaf} alt="leaf-icon" />
          <div className="left">
            <img src={employmentSideimg} alt="massage-demonstartion" />
          </div>
          <div className="right">
            <h3>Employment Opportunities</h3>
            <p className="desc">
              Looking for certified massage therapists who can offer exceptional
              service to clients, utilizing a range of techniques to alleviate
              pain and promote relaxation. Join our team and help clients
              achieve their wellness goals in a supportive, rewarding & safe
              environment.
              <span>Fill out the inquiry form below</span>
            </p>
          </div>
        </div>
      </div>
      <ContactSection />
    </>
  );
};

export default Employment;
