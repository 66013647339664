import React from "react";
import "./testimonials.scss";
import CommonSection from "../../components/common-section/CommonSection";
import { testimonials } from "../../assets/data/testimonials";
import TestimonialCard from "../../components/testimonial-card/TestimonialCard";
import Helmet from "../../components/helmet/Helmet";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <Helmet
        title={"Testimonials"}
        canonical={"https://www.massagenow-atl.com/what-people-are-saying"}
      />
      <CommonSection title={"Testimonial"} />

      {/* TESTIMONIALS */}
      <div className="all-testimonials">
        {testimonials.map((item, index) => (
          <TestimonialCard
            key={index}
            img={item.img}
            review={item.review}
            name={item.name}
            relation={item.relation}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
